import { InputHTMLAttributes } from "react";
import { DefaultTheme, styled } from "styled-components";
import Text from "../Text/Text";

interface SliderLabelProps {
  progress: string;
}

interface StyledInputProps extends InputHTMLAttributes<HTMLInputElement> {
  isMax: boolean;
  theme: DefaultTheme;
  progressPercentage: number;
}

interface DisabledProp {
  disabled?: boolean;
}

const getCursorStyle = ({ disabled = false }: DisabledProp) => {
  return disabled ? "not-allowed" : "pointer";
};

const getBaseThumbStyles = ({ isMax, disabled, theme, value, progressPercentage }: StyledInputProps) => `
  -webkit-appearance: none;
  background-color: ${theme.colors.primary};
  box-shadow: none;
  border: 0;
  cursor: ${getCursorStyle};
  width: 24px;
  height: 24px;
  border-radius: 50%;
  filter: ${disabled ? "grayscale(100%)" : "none"};
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 200ms transform;
  &:hover {
    // transform: ${disabled ? "scale(1) translate(-50%, -50%)" : "scale(1.1) translate(-50%, -50%)"};
  }
  position: absolute;
  left: ${progressPercentage}%;
`;

export const SliderLabelContainer = styled.div`
  bottom: 0;
  position: absolute;
  width: 100%;
`;

export const SliderLabel = styled(Text)<SliderLabelProps>`
  bottom: 0;
  font-size: 12px;
  left: ${({ progress }) => progress};
  position: absolute;
  text-align: center;
  min-width: 24px; // Slider thumb size
`;

export const BunnyButt = styled.div<DisabledProp>`
  height: 32px;
  filter: ${({ disabled }) => (disabled ? "grayscale(100%)" : "none")};
  position: absolute;
  width: 15px;
`;

export const BunnySlider = styled.div`
  position: absolute;
  // left: 14px;
  // width: calc(100% - 14px);
  width: 100%;
`;

export const StyledInput = styled.input<StyledInputProps>`
  cursor: ${getCursorStyle};
  height: 34px;
  position: relative;
  &::-webkit-slider-thumb {
    ${getBaseThumbStyles}
  }
  &::-moz-range-thumb {
    ${getBaseThumbStyles}
  }
  &::-ms-thumb {
    ${getBaseThumbStyles}
  }
`;

export const BarBackground = styled.div<DisabledProp>`
  background-color: ${({ theme, disabled }) => theme.colors[disabled ? "textDisabled" : "inputSecondary"]};
  height: 8px;
  position: absolute;
  top: 14px;
  width: 100%;
  border-radius: 4px;
`;

export const BarProgress = styled.div<DisabledProp>`
  background-color: ${({ theme }) => theme.colors.primary};
  filter: ${({ disabled }) => (disabled ? "grayscale(100%)" : "none")};
  height: 8px;
  position: absolute;
  top: 14px;
  border-radius: 4px;
`;
