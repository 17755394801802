import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 48 48" {...props}>
      <path d="M5.936,16h19v-4.363v-2c0-1.069,0.638-2.024,1.626-2.433c0.987-0.409,2.113-0.186,2.869,0.571l13.397,13.397	C43.584,21.927,44,22.932,44,24c0,0.344,0,1.656,0,2c0,1.068-0.416,2.073-1.172,2.828L29.431,42.226	c-0.505,0.505-1.175,0.772-1.859,0.772c-0.34,0-0.683-0.066-1.01-0.202c-0.988-0.409-1.626-1.364-1.626-2.433v-2V34h-19	c-1.654,0-3-1.346-3-3v-2v-8v-2C2.936,17.346,4.282,16,5.936,16z" />
      <path
        fill="#fff"
        d="M42.186,21.879L28.789,8.482C27.76,7.453,26,8.181,26,9.637V17H6c-1.105,0-2,0.895-2,2v10 c0,1.105,0.895,2,2,2h20v7.363c0,1.455,1.759,2.184,2.788,1.155l13.397-13.397C43.357,24.95,43.357,23.05,42.186,21.879z"
      />
      <path d="M27.637,40.998c-0.34,0-0.684-0.066-1.011-0.202C25.638,40.387,25,39.432,25,38.363V32H6c-1.654,0-3-1.346-3-3V19 c0-1.654,1.346-3,3-3h19V9.637c0-1.069,0.638-2.024,1.626-2.433c0.984-0.411,2.114-0.187,2.87,0.57l13.396,13.397 c0.756,0.755,1.172,1.76,1.172,2.828s-0.416,2.073-1.172,2.828L29.496,40.226C28.991,40.73,28.32,40.998,27.637,40.998z M6,18 c-0.552,0-1,0.449-1,1v10c0,0.551,0.448,1,1,1h20c0.553,0,1,0.448,1,1v7.363c0,0.382,0.273,0.537,0.392,0.585 c0.116,0.048,0.42,0.133,0.69-0.137l13.396-13.397c0.378-0.378,0.586-0.88,0.586-1.414s-0.208-1.036-0.586-1.414l0,0L28.082,9.188 c-0.272-0.271-0.574-0.187-0.69-0.137C27.273,9.1,27,9.255,27,9.637V17c0,0.552-0.447,1-1,1H6z" />
    </Svg>
  );
};

export default Icon;
