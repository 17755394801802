import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg width="20" height="20" viewBox="0 0 48 48" {...props}>
      <path d="M44,24H4c0,0.3,0,1.7,0,2c0,11,9,20,20,20s20-9,20-20C44,25.7,44,24.3,44,24z" />
      <circle cx="24" cy="24" r="19" fill="#fff" />
      <path
        d="M24,44C13,44,4,35,4,24S13,4,24,4s20,9,20,20S35,44,24,44z M24,6C14.1,6,6,14.1,6,24s8.1,18,18,18s18-8.1,18-18S33.9,6,24,6
	z"
      />
      <path d="M31,23H17c-0.6,0-1,0.4-1,1s0.4,1,1,1h14c0.6,0,1-0.4,1-1S31.6,23,31,23z" />
    </Svg>
  );
};

export default Icon;
