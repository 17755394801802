import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 312 312" {...props}>
      <g>
        <g id="mask0_1_9">
          <circle fill="#f6f1ea" cx="156" cy="156" r="156" />
        </g>
        <path fill="none" d="M266.28,266.34l.03-.03,.03-.03-.05,.05h-.01Z" />
        <path
          fill="none"
          d="M9.36,102.7l199.94,199.94c4.06-1.47,8.08-3.12,12.04-4.94L14.3,90.66c-1.82,3.95-3.46,7.97-4.94,12.04Z"
        />
        <path
          fill="none"
          d="M304.79,202.96L109.04,7.21c-10.19,3.21-20.11,7.48-29.59,12.83l212.52,212.52c5.35-9.48,9.62-19.41,12.83-29.59h0Z"
        />
        <path
          fill="none"
          d="M26.52,68.95l216.53,216.53c4.97-3.35,9.8-7.01,14.45-11L37.53,54.5c-3.99,4.65-7.65,9.47-11,14.45h0Z"
        />
        <path
          fill="none"
          d="M285.48,243.05L68.95,26.52c-6.6,4.45-12.93,9.45-18.93,15.02l220.44,220.44c5.56-6,10.57-12.33,15.02-18.93h0Z"
        />
        <path
          fill="none"
          d="M.04,152.78l159.19,159.19c2.71-.06,5.41-.18,8.11-.37L.41,144.66c-.2,2.7-.32,5.41-.37,8.11h0Z"
        />
        <path
          fill="#b1d5dc"
          d="M291.96,232.56L79.44,20.04c-3.56,2.01-7.06,4.17-10.49,6.48l216.52,216.52c2.31-3.43,4.47-6.93,6.48-10.49h0Z"
        />
        <path
          fill="#cadcb1"
          d="M306.7,196.39L115.61,5.3c-2.2,.59-4.39,1.23-6.57,1.92l195.75,195.75c.69-2.18,1.33-4.37,1.92-6.57h-.01Z"
        />
        <path
          fill="#afa4f5"
          d="M270.46,261.98L50.02,41.54c-1.46,1.35-2.91,2.73-4.33,4.15-2.86,2.86-5.58,5.79-8.16,8.81l219.97,219.97c3-2.58,5.93-5.29,8.78-8.14l.05-.05c1.41-1.41,2.78-2.85,4.13-4.3Z"
        />
        <path
          fill="#edabd9"
          d="M14.3,90.66l207.04,207.04c7.48-3.45,14.74-7.53,21.72-12.23L26.52,68.95c-4.7,6.97-8.78,14.23-12.23,21.72h0Z"
        />
        <path
          fill="#e1846c"
          d="M.41,144.66l166.93,166.93c14.25-1.03,28.38-4.02,41.96-8.95L9.36,102.7c-4.93,13.59-7.92,27.72-8.95,41.96Z"
        />
        <path
          fill="#eedb76"
          d="M45.69,266.31c31.28,31.28,72.54,46.5,113.53,45.65L.04,152.78c-.84,40.99,14.37,82.25,45.65,113.53Z"
        />
      </g>
    </Svg>
  );
};

export default Icon;
