import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 48 48" {...props}>
      <path d="M44.607,37.487L26.606,6.309c-0.544-0.941-1.518-1.503-2.604-1.503c-1.086,0-2.06,0.562-2.604,1.503L3.398,37.487	c-0.253,0.439-0.381,0.915-0.392,1.395c-0.009,0.374-0.006,1.83,0,2.196c0.007,0.488,0.136,0.973,0.394,1.419	C3.942,43.438,4.916,44,6.002,44h36.001c1.086,0,2.06-0.562,2.603-1.503c0.274-0.475,0.402-0.994,0.394-1.513	c-0.006-0.335-0.011-1.621-0.001-1.941C45.015,38.509,44.888,37.975,44.607,37.487z" />
      <path
        fill="#fff"
        d="M22.268,6.824L4.269,38c-0.77,1.333,0.192,3,1.732,3h35.999c1.54,0,2.502-1.667,1.732-3L25.732,6.824	C24.962,5.491,23.038,5.491,22.268,6.824z"
      />
      <path d="M42,42H6c-1.084,0-2.056-0.561-2.598-1.5c-0.542-0.939-0.542-2.061,0-3l18-31.176c0.542-0.939,1.514-1.5,2.598-1.5	s2.056,0.561,2.598,1.5l18,31.176c0.542,0.939,0.542,2.061,0,3C44.055,41.439,43.084,42,42,42z M24,6.824	c-0.173,0-0.605,0.049-0.866,0.5l0,0l-18,31.176c-0.261,0.451-0.087,0.85,0,1C5.221,39.65,5.479,40,6,40H42	c0.521,0,0.779-0.35,0.866-0.5c0.086-0.15,0.26-0.549,0-1l-18-31.176C24.605,6.873,24.173,6.824,24,6.824z M22.268,6.824h0.01	H22.268z" />
      <path d="M24.5,37h-1c-0.552,0-1-0.448-1-1v-2h3v2C25.5,36.552,25.052,37,24.5,37z" />
      <path d="M24.884,31h-1.768c-0.347,0-0.636-0.268-0.663-0.614L21.5,18h5l-0.953,12.386C25.521,30.732,25.232,31,24.884,31z" />
    </Svg>
  );
};

export default Icon;
