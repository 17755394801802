import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const ZoomOut: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg width={18} height={18} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M46,40.02c0.005-0.262-0.092-0.526-0.293-0.727l-9-9c-0.391-0.391-1.023-0.391-1.414,0L35,30.586l-1.953-1.953	C34.287,26.362,35,23.764,35,21c0-0.289,0-1.615,0-2c0-8.822-7.178-16-16-16S3,10.178,3,19c0,0.338,0,1.662,0,2	c0,8.822,7.178,16,16,16c3.285,0,6.34-0.998,8.884-2.702l2.409,2.409L30.586,37l-0.293,0.293c-0.391,0.391-0.391,1.023,0,1.414	l7.274,7.274c0.671,0.671,1.552,1.006,2.433,1.006s1.762-0.335,2.433-1.006l3.274-3.274C45.902,42.512,46,42.256,46,42	C46,41.744,45.995,40.269,46,40.02z" />
      <rect width="7.071" height="4.243" x="27.464" y="28.879" fill="#fff" transform="rotate(45.001 31 31)" />
      <path d="M32,36c-0.256,0-0.512-0.098-0.707-0.293l-5-5c-0.391-0.391-0.391-1.023,0-1.414l3-3c0.391-0.391,1.023-0.391,1.414,0l5,5	c0.391,0.391,0.391,1.023,0,1.414l-3,3C32.512,35.902,32.256,36,32,36z M28.414,30L32,33.586L33.586,32L30,28.414L28.414,30z" />
      <path fill="#fff" d="M31,36l7.275,7.275c0.953,0.953,2.498,0.953,3.451,0L45,40l-9-9L31,36z" />
      <path d="M40,44.989c-0.919,0-1.783-0.358-2.433-1.008l-7.274-7.274C30.105,36.52,30,36.266,30,36s0.105-0.52,0.293-0.707l5-5	c0.391-0.391,1.023-0.391,1.414,0l9,9C45.895,39.48,46,39.734,46,40s-0.105,0.52-0.293,0.707l-3.275,3.274	C41.783,44.631,40.919,44.989,40,44.989z M32.414,36l6.568,6.567c0.544,0.545,1.493,0.545,2.036,0L43.586,40L36,32.414L32.414,36z" />
      <circle cx="19" cy="19" r="15" fill="#fff" />
      <path d="M19,35c-8.822,0-16-7.178-16-16S10.178,3,19,3s16,7.178,16,16S27.822,35,19,35z M19,5C11.28,5,5,11.28,5,19s6.28,14,14,14	s14-6.28,14-14S26.72,5,19,5z" />
      <path d="M25,20c0.552,0,1-0.448,1-1s-0.448-1-1-1c-0.3,0-11.7,0-12,0c-0.552,0-1,0.448-1,1s0.448,1,1,1C13.3,20,24.7,20,25,20z" />
    </Svg>
  );
};

export default ZoomOut;
