import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 3H8.5L12.9224 8.89655L18 3H21L14.319 10.7586L22 21H16L11.1121 14.4828L5.5 21H2.5L9.71552 12.6207L2.5 3ZM18.5 19.25H16.875L6 4.75H7.625L18.5 19.25Z"
      />
    </Svg>
  );
};

export default Icon;
