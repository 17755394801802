import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 48 48" {...props}>
      <path d="M41,15H22v-4.363v-2c0-1.069-0.638-2.024-1.626-2.433c-0.987-0.409-2.113-0.186-2.869,0.571L4.108,20.172	C3.352,20.927,2.936,21.932,2.936,23c0,0.344,0,1.656,0,2c0,1.068,0.416,2.073,1.172,2.828l13.397,13.397	c0.505,0.505,1.175,0.772,1.859,0.772c0.34,0,0.683-0.066,1.01-0.202C21.362,41.387,22,40.432,22,39.363v-2V33h19	c1.654,0,3-1.346,3-3v-2v-8v-2C44,16.346,42.654,15,41,15z" />
      <path
        fill="#fff"
        d="M21,16V8.637c0-1.455-1.759-2.184-2.788-1.155L4.815,20.879c-1.172,1.172-1.172,3.071,0,4.243 l13.397,13.397c1.029,1.029,2.788,0.3,2.788-1.155V30h20c1.105,0,2-0.895,2-2V16H21z"
      />
      <path d="M19.364,39.998c-0.684,0-1.354-0.268-1.859-0.772L4.108,25.828C3.352,25.073,2.936,24.068,2.936,23 s0.416-2.073,1.172-2.828L17.505,6.774c0.756-0.757,1.882-0.98,2.869-0.571C21.362,6.613,22,7.568,22,8.637V15h21 c0.552,0,1,0.448,1,1v12c0,1.654-1.346,3-3,3H22v6.363c0,1.069-0.638,2.024-1.626,2.433C20.046,39.932,19.704,39.998,19.364,39.998 z M19.354,8c-0.131,0-0.288,0.042-0.435,0.189L5.522,21.586C5.144,21.964,4.936,22.466,4.936,23s0.208,1.036,0.586,1.414 l13.397,13.397c0.27,0.27,0.571,0.186,0.69,0.137C19.727,37.9,20,37.745,20,37.363V30c0-0.552,0.448-1,1-1h20c0.551,0,1-0.449,1-1 V17H21c-0.552,0-1-0.448-1-1V8.637c0-0.382-0.273-0.537-0.391-0.585C19.555,8.029,19.463,8,19.354,8z" />
    </Svg>
  );
};

export default Icon;
