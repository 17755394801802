import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 48 48" {...props}>
      <path d="M41,43H7c-1.657,0-3-1.343-3-3v-7h40v7C44,41.657,42.657,43,41,43z" />
      <path fill="#fff" d="M41,14v6H5v-8c0-1.1,0.9-2,2-2h26v4H41z" />
      <path d="M40,14v5H6v-7c0-0.55,0.45-1,1-1h26V9H7c-1.65,0-3,1.35-3,3v8c0,0.55,0.45,1,1,1h36c0.55,0,1-0.45,1-1v-6H40z" />
      <circle cx="31.75" cy="12.5" r="5.5" fill="#fff" />
      <path d="M31.75,19c-3.584,0-6.5-2.916-6.5-6.5S28.166,6,31.75,6s6.5,2.916,6.5,6.5S35.334,19,31.75,19z M31.75,8	c-2.481,0-4.5,2.019-4.5,4.5s2.019,4.5,4.5,4.5s4.5-2.019,4.5-4.5S34.231,8,31.75,8z" />
      <circle cx="20.5" cy="10.25" r="6.75" fill="#fff" />
      <path d="M20.5,18c-4.273,0-7.75-3.477-7.75-7.75S16.227,2.5,20.5,2.5s7.75,3.477,7.75,7.75S24.773,18,20.5,18z M20.5,4.5	c-3.17,0-5.75,2.579-5.75,5.75S17.33,16,20.5,16s5.75-2.579,5.75-5.75S23.67,4.5,20.5,4.5z" />
      <path
        fill="#fff"
        d="M7,14c-1.105,0-2-0.895-2-2v26c0,1.105,0.895,2,2,2h34c1.105,0,2-0.895,2-2V16c0-1.105-0.895-2-2-2H7	z"
      />
      <path d="M41,41H7c-1.654,0-3-1.346-3-3V12c0-0.553,0.448-1,1-1s1,0.447,1,1c0,0.552,0.449,1,1,1h34c1.654,0,3,1.346,3,3v22	C44,39.654,42.654,41,41,41z M6,14.829V38c0,0.552,0.449,1,1,1h34c0.551,0,1-0.448,1-1V16c0-0.552-0.449-1-1-1H7	C6.649,15,6.313,14.939,6,14.829z" />
      <circle cx="36" cy="27" r="2" />
    </Svg>
  );
};

export default Icon;
