import React from "react";
import { styled } from "styled-components";
import { SpinnerProps } from "./types";
import { Box } from "../Box";

const StyledSpinner = styled(Box)`
  position: relative;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  margin: 0 auto;

  div {
    animation: spinner-4t3wzl 2s infinite backwards;
    background-color: #eedb76;
    border-radius: 50%;
    height: 100%;
    position: absolute;
    width: 100%;
  }

  div:nth-child(1) {
    animation-delay: 0.15s;
    background-color: #eedb76;
  }

  div:nth-child(2) {
    animation-delay: 0.3s;
    background-color: #e1846c;
  }

  div:nth-child(3) {
    animation-delay: 0.45s;
    background-color: #edabd9;
  }

  div:nth-child(4) {
    animation-delay: 0.6s;
    background-color: #afa4f5;
  }

  div:nth-child(5) {
    animation-delay: 0.75s;
    background-color: #b1d5dc;
  }

  div:nth-child(6) {
    animation-delay: 0.9s;
    background-color: #cadcb1;
  }

  @keyframes spinner-4t3wzl {
    0% {
      transform: rotate(0deg) translateY(-200%);
    }

    60%,
    100% {
      transform: rotate(360deg) translateY(-200%);
    }
  }
`;

const Spinner: React.FC<React.PropsWithChildren<SpinnerProps>> = ({ size = 128 }) => {
  return (
    <Box width={size} height={size} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <StyledSpinner size={size / 6}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </StyledSpinner>
    </Box>
  );
};

export default Spinner;
