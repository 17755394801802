import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        d="M4.998,42.001l-0.001-2	c-0.001-0.552,0.447-1.001,1-1.001h36.005c0.552,0,1,0.449,0.999,1.001v2c0,1.656-1.344,2.999-3,2.999H7.998	C6.341,44.999,4.998,43.657,4.998,42.001z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M5.998,39.999	L5.995,6.001h36.007l-0.001,33.999c0,1.104-0.896,2-2,2H7.998C6.894,41.999,5.998,41.103,5.998,39.999z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M4.998,40.001L4.995,6.002	c-0.001-0.552,0.447-1.001,1-1.001h36.007c0.551,0,1,0.449,0.999,1.001v33.999c0,1.656-1.344,2.999-3,2.999H7.998	C6.341,42.999,4.998,41.657,4.998,40.001z M41.001,39.999V7.001H6.995l0.003,32.999c0,0.552,0.448,1,1,1h32.003	C40.551,40.999,41.001,40.551,41.001,39.999z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M9.997,14.999c0-0.551,0.448-1,1-1h8	c0.551,0,1,0.449,1,1c0,0.552-0.449,1-1,1h-8C10.445,15.999,9.997,15.551,9.997,14.999z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M13.997,18.999v-8	c0-0.551,0.448-1,1-1c0.551,0,1,0.449,1,1v8c0,0.552-0.449,1-1,1C14.445,19.999,13.997,19.551,13.997,18.999z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M9.999,33.001c0-0.551,0.448-1,1-1h8	c0.551,0,1,0.449,1,1c0,0.552-0.449,1-1,1h-8C10.447,34.001,9.999,33.553,9.999,33.001z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M27.999,22.001c0-0.551,0.448-1,1-1h8	c0.551,0,1,0.449,1,1c0,0.552-0.449,1-1,1h-8C28.447,23.001,27.999,22.553,27.999,22.001z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M27.999,26.001c0-0.551,0.448-1,1-1h8	c0.551,0,1,0.449,1,1c0,0.552-0.449,1-1,1h-8C28.447,27.001,27.999,26.553,27.999,26.001z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M5.998,24.002c0-0.551,0.448-1,1-1	h17.001c0.551,0,1,0.449,1,1c0,0.552-0.449,1-1,1H6.998C6.446,25.002,5.998,24.554,5.998,24.002z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M22.998,41.002v-34	c0-0.551,0.448-1,1-1c0.551,0,1,0.449,1,1v34c0,0.552-0.449,1-1,1C23.446,42.002,22.998,41.554,22.998,41.002z"
        clipRule="evenodd"
      />
    </Svg>
  );
};

export default Icon;
