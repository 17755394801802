export const fetchAceTokenPrice = async (tokenAddress: string) => {
  // const [t24, t48, t7d] = getDeltaTimestamps()
  // const timestampsString = JSON.stringify([t24, t48, t7d])
  // const timestampsArray = JSON.parse(timestampsString)

  // const blocks = await getBlocksFromTimestamps(timestampsArray, 'desc', 1000, 'BSC')

  // const result = await fetchedTokenDatas(
  //   v3InfoClients[ChainId.BSC],
  //   [tokenAddress.toLowerCase()],
  //   blocks?.filter((d) => d.number >= SUBGRAPH_START_BLOCK[ChainId.BSC]),
  // )

  // return result?.data?.[tokenAddress?.toLowerCase()]?.priceUSD ?? 0
  return 0
}
