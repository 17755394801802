import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 48 48" {...props}>
      <path d="M44,24H4c0,0.338,0,1.662,0,2c0,11.028,8.972,20,20,20s20-8.972,20-20C44,25.662,44,24.338,44,24z" />
      <circle cx="24" cy="24" r="19" fill="#fff" />
      <path d="M24,44C12.972,44,4,35.028,4,24S12.972,4,24,4s20,8.972,20,20S35.028,44,24,44z M24,6C14.075,6,6,14.075,6,24	s8.075,18,18,18s18-8.075,18-18S33.925,6,24,6z" />
      <path d="M24.5,35h-1c-0.552,0-1-0.448-1-1v-2h3v2C25.5,34.552,25.052,35,24.5,35z" />
      <path d="M18,20h3c0,0,0.312-3,3-3c1.5,0,3,1.224,3,2.976c0,3.551-4.176,4.085-4.482,8.502C22.498,28.761,22.736,29,23.02,29h2.069	c0.235,0,0.419-0.177,0.465-0.408C26.155,25.569,31,24.866,31,20.015C31,17.111,29.084,14,24.5,14C18.633,14,18,18.756,18,20z" />
    </Svg>
  );
};

export default Icon;
