import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const ZoomIn: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg width={18} height={18} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M46,40c0-0.3-0.1-0.5-0.3-0.7l-9-9c-0.4-0.4-1-0.4-1.4,0L35,30.6l-2-2c1.2-2.3,2-4.9,2-7.6c0-0.3,0-1.6,0-2
	c0-8.8-7.2-16-16-16S3,10.2,3,19c0,0.3,0,1.7,0,2c0,8.8,7.2,16,16,16c3.3,0,6.3-1,8.9-2.7l2.4,2.4l0.3,0.3l-0.3,0.3
	c-0.4,0.4-0.4,1,0,1.4l7.3,7.3c0.7,0.7,1.6,1,2.4,1s1.8-0.3,2.4-1l3.3-3.3c0.2-0.2,0.3-0.5,0.3-0.7S46,40.3,46,40z"
      />
      <rect
        x="28.9"
        y="27.5"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -12.8408 30.9987)"
        fill="#fff"
        width="4.2"
        height="7.1"
      />
      <path
        d="M32,36c-0.3,0-0.5-0.1-0.7-0.3l-5-5c-0.4-0.4-0.4-1,0-1.4l3-3c0.4-0.4,1-0.4,1.4,0l5,5c0.4,0.4,0.4,1,0,1.4l-3,3
	C32.5,35.9,32.3,36,32,36z M28.4,30l3.6,3.6l1.6-1.6L30,28.4L28.4,30z"
      />
      <path fill="#fff" d="M31,36l7.3,7.3c1,1,2.5,1,3.5,0L45,40l-9-9L31,36z" />
      <path
        d="M40,45c-0.9,0-1.8-0.4-2.4-1l-7.3-7.3C30.1,36.5,30,36.3,30,36s0.1-0.5,0.3-0.7l5-5c0.4-0.4,1-0.4,1.4,0l9,9
	c0.2,0.2,0.3,0.4,0.3,0.7s-0.1,0.5-0.3,0.7L42.4,44C41.8,44.6,40.9,45,40,45z M32.4,36l6.6,6.6c0.5,0.5,1.5,0.5,2,0l2.6-2.6L36,32.4
	L32.4,36z"
      />
      <circle fill="#fff" cx="19" cy="19" r="15" />
      <path
        d="M19,35c-8.8,0-16-7.2-16-16S10.2,3,19,3s16,7.2,16,16S27.8,35,19,35z M19,5C11.3,5,5,11.3,5,19s6.3,14,14,14s14-6.3,14-14
	S26.7,5,19,5z"
      />
      <path
        d="M25,18c-0.1,0-2.4,0-5,0c0-2.6,0-4.9,0-5c0-0.6-0.4-1-1-1s-1,0.4-1,1c0,0.1,0,2.4,0,5c-2.6,0-4.9,0-5,0c-0.6,0-1,0.4-1,1
	s0.4,1,1,1c0.1,0,2.4,0,5,0c0,2.6,0,4.9,0,5c0,0.6,0.4,1,1,1s1-0.4,1-1c0-0.1,0-2.4,0-5c2.6,0,4.9,0,5,0c0.6,0,1-0.4,1-1
	S25.6,18,25,18z"
      />
    </Svg>
  );
};

export default ZoomIn;
