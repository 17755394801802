import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | SectaFinance',
  defaultTitle: 'SectaFinance',
  description:
    "Secta is a decentralized exchange (DEX) and Launchpad fostering the growth of the Linea ecosystem by allowing the users to invest in projects to help grow Linea together with everyone's incentives aligned.",
  twitter: {
    cardType: 'summary_large_image',
    handle: '@SectaFinance',
    site: '@SectaFinance',
  },
  openGraph: {
    title: 'SectaFinance - A next evolution DeFi exchange on Linea',
    description:
      "Secta is a decentralized exchange (DEX) and Launchpad fostering the growth of the Linea ecosystem by allowing the users to invest in projects to help grow Linea together with everyone's incentives aligned.",
    images: [{ url: 'https://secta.finance/images/hero.jpg' }],
  },
}
