import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 48 48" {...props}>
      <path d="M24,6c-0.552,0-1,0.448-1,1v4c0,0.552,0.448,1,1,1c7.72,0,14,6.28,14,14s-6.28,14-14,14s-14-6.28-14-14	c0-2.405,0.628-4.755,1.825-6.858l3.546,2.658c0.304,0.228,0.709,0.263,1.047,0.094c0.339-0.169,0.553-0.516,0.552-0.895	L16.956,9.014c0-0.308-0.143-0.599-0.385-0.788c-0.244-0.189-0.558-0.256-0.858-0.182l-11.971,3	c-0.374,0.094-0.661,0.395-0.737,0.772c-0.076,0.378,0.072,0.767,0.38,0.998l3.614,2.709C5.034,18.684,4,22.286,4,26	c0,11.028,8.972,20,20,20s20-8.972,20-20S35.028,6,24,6z" />
      <path
        fill="#fff"
        d="M24,5v4c8.271,0,15,6.729,15,15s-6.729,15-15,15c-8.271,0-15-6.729-15-15	c0-3.079,0.951-5.931,2.551-8.313L15.971,19L15.956,7.015l-11.971,3l4.364,3.272C6.247,16.338,5,20.021,5,24	c0,10.477,8.523,19,19,19s19-8.523,19-19S34.477,5,24,5z"
      />
      <path d="M24,44C12.972,44,4,35.028,4,24c0-3.714,1.034-7.316,2.999-10.476l-3.614-2.709c-0.309-0.231-0.457-0.62-0.38-0.998	c0.076-0.378,0.363-0.679,0.737-0.772l11.971-3c0.3-0.074,0.614-0.008,0.858,0.182c0.243,0.189,0.385,0.479,0.385,0.788	l0.015,11.985c0,0.379-0.213,0.726-0.552,0.895c-0.338,0.169-0.744,0.134-1.047-0.094l-3.546-2.658C10.628,19.245,10,21.595,10,24	c0,7.72,6.28,14,14,14s14-6.28,14-14s-6.28-14-14-14c-0.552,0-1-0.448-1-1V5c0-0.552,0.448-1,1-1c11.028,0,20,8.972,20,20	S35.028,44,24,44z M6.265,10.474l2.685,2.012c0.429,0.322,0.528,0.926,0.223,1.368C7.097,16.866,6,20.374,6,24	c0,9.925,8.075,18,18,18s18-8.075,18-18c0-9.59-7.538-17.452-17-17.973v2.003C33.358,8.549,40,15.514,40,24c0,8.822-7.178,16-16,16	S8,32.822,8,24c0-3.152,0.941-6.219,2.721-8.871c0.153-0.228,0.393-0.382,0.663-0.428c0.27-0.046,0.548,0.021,0.767,0.186	l2.817,2.112l-0.011-8.703L6.265,10.474z" />
      <rect width="2" height="2" x="3" y="10" />
    </Svg>
  );
};

export default Icon;
