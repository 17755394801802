import { useIsMounted } from "@pancakeswap/hooks";
import { useTranslation } from "@pancakeswap/localization";
import { AutoColumn, IconButton, PencilIcon, RowBetween, Text, TextProps } from "@pancakeswap/uikit";
import { PropsWithChildren, ReactNode } from "react";

type SwapInfoType = {
  price: ReactNode;
  allowedSlippage?: number;
  onSlippageClick?: () => void;
  allowedSlippageSlot?: React.ReactNode;
};

export const SwapInfoLabel = (props: PropsWithChildren<TextProps>) => (
  <Text fontSize="16px" color="secondary" className="font-title uppercase" {...props} />
);

export const SwapInfo = ({ allowedSlippage, price, onSlippageClick, allowedSlippageSlot }: SwapInfoType) => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();

  return (
    <AutoColumn gap="sm" py="0px" px="0px">
      <RowBetween alignItems="center">{price}</RowBetween>
      {typeof allowedSlippage === "number" && (
        <RowBetween alignItems="center">
          <SwapInfoLabel>{t("Slippage")}</SwapInfoLabel>
          <div className="inline-flex">
            {isMounted &&
              (allowedSlippageSlot ?? (
                <Text bold color="primary" className="pl-3">
                  {allowedSlippage / 100}%
                </Text>
              ))}
            {onSlippageClick ? (
              <IconButton scale="xs" variant="text" onClick={onSlippageClick}>
                <PencilIcon color="text" width="16px" />
              </IconButton>
            ) : null}
          </div>
        </RowBetween>
      )}
    </AutoColumn>
  );
};
