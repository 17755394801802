import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Logo: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 844 179" {...props}>
      <path
        d="M832.5 133.049L710.91 11.4587C708.87 12.6087 706.87 13.8487 704.91 15.1687L828.8 139.059C830.12 137.099 831.36 135.099 832.51 133.059H832.5V133.049Z"
        fill="#B2D5DC"
      />
      <path
        d="M840.94 112.359L731.6 3.01874C730.34 3.35874 729.09 3.71874 727.84 4.11874L839.84 116.119C840.23 114.869 840.6 113.619 840.94 112.359Z"
        fill="#CBDCB1"
      />
      <path
        d="M820.2 149.889L694.07 23.7587C693.23 24.5387 692.41 25.3187 691.59 26.1387C689.96 27.7687 688.4 29.4487 686.92 31.1787L812.78 157.039C814.5 155.559 816.17 154.009 817.8 152.379L817.83 152.349C818.64 151.539 819.42 150.719 820.19 149.889H820.2Z"
        fill="#AFA3D0"
      />
      <path
        d="M673.64 51.8687L792.1 170.329C796.38 168.359 800.54 166.019 804.52 163.329L680.63 39.4387C677.94 43.4287 675.61 47.5787 673.63 51.8587H673.64V51.8687Z"
        fill="#E7ACCE"
      />
      <path
        d="M665.69 82.7587L761.2 178.269C769.35 177.679 777.44 175.969 785.21 173.149L670.81 58.7487C667.99 66.5187 666.28 74.6087 665.69 82.7587Z"
        fill="#E1856C"
      />
      <path
        d="M691.6 152.369C709.5 170.269 733.11 178.969 756.56 178.489L665.48 87.4087C665 110.859 673.7 134.469 691.6 152.369Z"
        fill="#EEDB77"
      />
      <path
        d="M1.88 133.049C40.03 135.139 65.86 136.179 79.36 136.179C82.56 136.179 85.1 135.239 86.98 133.359C88.86 131.479 89.8 128.939 89.8 125.739V106.939H41.76C27.14 106.939 16.52 103.629 9.91 97.0187C3.31 90.4087 0 79.7887 0 65.1687V58.8987C0 44.2787 3.31 33.6587 9.92 27.0487C16.53 20.4387 27.15 17.1287 41.77 17.1287H115.08V46.3687H50.12C41.77 46.3687 37.59 50.5487 37.59 58.8987V63.0787C37.59 71.4287 41.77 75.6087 50.12 75.6087H87.71C101.35 75.6087 111.38 78.8087 117.78 85.2187C124.18 91.6287 127.39 101.649 127.39 115.299V125.739C127.39 139.389 124.19 149.409 117.78 155.809C111.37 162.209 101.35 165.419 87.71 165.419C80.61 165.419 73.09 165.279 65.15 164.999L45.94 164.159C31.74 163.459 17.05 162.489 1.87 161.239V133.049H1.88Z"
        fill="#303030"
      />
      <path
        d="M262.11 163.329H146.2V17.1287H262.11V46.3687H183.79V74.5587H245.4V103.799H183.79V134.079H262.11V163.319V163.329Z"
        fill="#303030"
      />
      <path
        d="M391.59 161.239C360.54 164.019 334.78 165.419 314.32 165.419C300.67 165.419 290.65 162.219 284.25 155.809C277.84 149.409 274.64 139.379 274.64 125.739V58.8987C274.64 44.2787 277.95 33.6587 284.56 27.0487C291.17 20.4387 301.79 17.1287 316.41 17.1287H391.6V46.3687H324.77C316.42 46.3687 312.24 50.5487 312.24 58.8987V125.729C312.24 128.929 313.18 131.469 315.06 133.349C316.94 135.229 319.34 136.169 322.27 136.169C325.2 136.169 328.4 136.139 331.88 136.069C335.36 135.999 338.98 135.899 342.74 135.759C346.5 135.619 350.29 135.479 354.12 135.339C357.95 135.199 362.89 134.919 368.95 134.509C375.01 134.099 382.56 133.609 391.61 133.049V161.239H391.59Z"
        fill="#303030"
      />
      <path d="M479.3 163.329H441.71V46.3687H397.85V17.1287H523.16V46.3687H479.3V163.329Z" fill="#303030" />
      <path
        d="M549.27 163.329H508.54L563.88 17.1287H605.65L660.99 163.329H620.26L610.86 137.219H558.65L549.25 163.329H549.27ZM568.07 110.069H601.49L584.78 60.9887L568.07 110.069Z"
        fill="#303030"
      />
    </Svg>
  );
};

export default Logo;
