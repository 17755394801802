import React from "react";
import { useMatchBreakpoints } from "../../contexts";
import { Flex } from "../Box";
import { Link } from "../Link";
import { StyledFooter, StyledList, StyledListItem, StyledText } from "./styles";

import { vars } from "../../css/vars.css";
import { LogoIcon, LogoWithTextIcon } from "../Svg";
import SocialLinks from "./Components/SocialLinks";
import { FooterProps } from "./types";

const MenuItem: React.FC<React.PropsWithChildren<FooterProps>> = ({ items, ...props }) => {
  const { isXl } = useMatchBreakpoints();
  return (
    <StyledFooter
      data-theme="light"
      p={["40px 16px", null, "56px 40px 32px 40px"]}
      position="relative"
      {...props}
      justifyContent="center"
    >
      <Flex flexDirection="column" width={["100%", null, "1200px;"]}>
        <Flex
          order={[2, null, 1]}
          flexDirection={["column", "column", "column", "column", "row", "row"]}
          justifyContent="space-between"
          alignItems="flex-start"
          mb={["42px", null, "36px"]}
        >
          <Flex flexDirection="column" display={["none", null, "flex"]} justifyContent="space-between" height="100%">
            {isXl ? <LogoIcon /> : <LogoWithTextIcon width="160px" />}
            <SocialLinks order={[2]} pt={["20px", null, null, "0px"]} pb={["20px", null, null, "0px"]} />
          </Flex>

          {items?.map((item) => (
            <StyledList key={item.label}>
              <StyledListItem style={{ fontWeight: 500, fontFamily: "Anton" }}>{item.label}</StyledListItem>
              {item.items?.map(({ label, href, isHighlighted = false }) => (
                <StyledListItem key={label}>
                  {href ? (
                    <Link
                      data-theme="light"
                      href={href}
                      {...(href.startsWith("http") && { target: "_blank" })}
                      rel="noreferrer noopener"
                      color={isHighlighted ? vars.colors.warning : "text"}
                      bold={false}
                    >
                      {label}
                    </Link>
                  ) : (
                    <StyledText>{label}</StyledText>
                  )}
                </StyledListItem>
              ))}
            </StyledList>
          ))}
        </Flex>
      </Flex>
    </StyledFooter>
  );
};

export default MenuItem;
