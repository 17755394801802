import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 48 48" {...props}>
      <path d="M35,46H13c-1.657,0-3-1.343-3-3v-6h28v6C38,44.657,36.657,46,35,46z" />
      <linearGradient id="-hbxqbGYX8Ntfu_iC0-48a" x1="20" x2="45" y1="25" y2="25" gradientUnits="userSpaceOnUse">
        <stop offset="0" stop-color="#fff" />
        <stop offset="1" />
      </linearGradient>
      <path
        fill="url(#-hbxqbGYX8Ntfu_iC0-48a)"
        d="M34,28H21c-0.552,0-1-0.448-1-1v-4c0-0.552,0.448-1,1-1h13v-4.379	c0-1.336,1.616-2.006,2.561-1.061L45,23v2l-8.439,8.439C35.616,34.384,34,33.715,34,32.379V28z"
      />
      <path d="M35.502,34.88c-0.322,0-0.647-0.062-0.959-0.191C33.605,34.3,33,33.394,33,32.379V29H21c-1.103,0-2-0.897-2-2v-4	c0-1.103,0.897-2,2-2h12v-3.379c0-1.015,0.605-1.921,1.543-2.31c0.916-0.38,1.955-0.186,2.671,0.489l8.393,6.404	C45.854,22.394,46,22.688,46,23v2c0,0.265-0.105,0.52-0.293,0.707l-8.439,8.439C36.788,34.626,36.151,34.88,35.502,34.88z" />
      <path fill="#fff" d="M35,42H13c-1.105,0-2-0.895-2-2V4h26v36C37,41.105,36.105,42,35,42z" />
      <path d="M35,43H13c-1.654,0-3-1.346-3-3V4c0-0.553,0.447-1,1-1h26c0.553,0,1,0.447,1,1v36C38,41.654,36.654,43,35,43z M12,5v35	c0,0.552,0.448,1,1,1h22c0.552,0,1-0.448,1-1V5H12z" />
      <path
        fill="#fff"
        d="M34,26H21c-0.552,0-1-0.448-1-1v-4c0-0.552,0.448-1,1-1h13v-4.379c0-1.336,1.616-2.006,2.561-1.061	L45,23l-8.439,8.439C35.616,32.384,34,31.715,34,30.379V26z"
      />
      <path d="M35.502,32.88c-0.322,0-0.647-0.062-0.959-0.191C33.605,32.3,33,31.394,33,30.379V27H21c-1.103,0-2-0.897-2-2v-4	c0-1.103,0.897-2,2-2h12v-3.379c0-1.015,0.605-1.921,1.543-2.31c0.939-0.389,2.007-0.175,2.725,0.542l8.439,8.44	c0.391,0.391,0.391,1.023,0,1.414l-8.439,8.439C36.788,32.626,36.151,32.88,35.502,32.88z M21,21v4h13c0.553,0,1,0.448,1,1v4.379	c0,0.301,0.216,0.423,0.309,0.462c0.092,0.037,0.33,0.104,0.545-0.109L43.586,23l-7.732-7.732c-0.215-0.213-0.453-0.146-0.545-0.108	C35.216,15.198,35,15.32,35,15.621V20c0,0.552-0.447,1-1,1H21z" />
    </Svg>
  );
};

export default Icon;
