import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 48 48" {...props}>
      <path d="M37,45c-0.256,0-0.512-0.098-0.707-0.293L24,32.414L11.707,44.707c-0.391,0.391-1.023,0.391-1.414,0l-5-5	C5.105,39.52,5,39.265,5,39v-2c0-0.294,0.129-0.573,0.354-0.763l12.17-10.298L5.293,13.707C5.105,13.52,5,13.265,5,13v-2	c0-0.351,0.184-0.677,0.485-0.857l5-3c0.394-0.235,0.898-0.173,1.222,0.15L24,19.586L36.293,7.293	c0.324-0.324,0.829-0.386,1.222-0.15l5,3C42.816,10.323,43,10.649,43,11v2c0,0.265-0.105,0.52-0.293,0.707L30.476,25.938	l12.17,10.298C42.871,36.427,43,36.706,43,37v2c0,0.265-0.105,0.52-0.293,0.707l-5,5C37.512,44.902,37.256,45,37,45z" />
      <polygon fill="#fff" points="42,37 29,24 42,11 37,6 24,19 11,6 6,11 19,24 6,37 11,42 24,29 37,42" />
      <path d="M37,43c-0.256,0-0.512-0.098-0.707-0.293L24,30.414L11.707,42.707c-0.391,0.391-1.023,0.391-1.414,0l-5-5	c-0.391-0.391-0.391-1.023,0-1.414L17.586,24L5.293,11.707c-0.391-0.391-0.391-1.023,0-1.414l5-5c0.391-0.391,1.023-0.391,1.414,0	L24,17.586L36.293,5.293c0.391-0.391,1.023-0.391,1.414,0l5,5c0.391,0.391,0.391,1.023,0,1.414L30.414,24l12.293,12.293	c0.391,0.391,0.391,1.023,0,1.414l-5,5C37.512,42.902,37.256,43,37,43z M24,28c0.256,0,0.512,0.098,0.707,0.293L37,40.586L40.586,37	L28.293,24.707c-0.391-0.391-0.391-1.023,0-1.414L40.586,11L37,7.414L24.707,19.707c-0.391,0.391-1.023,0.391-1.414,0L11,7.414	L7.414,11l12.293,12.293c0.391,0.391,0.391,1.023,0,1.414L7.414,37L11,40.586l12.293-12.293C23.488,28.098,23.744,28,24,28z" />
    </Svg>
  );
};

export default Icon;
